<template>
  <div class="step-content">
    <v-card 
      v-if="!!this.controller.selected||hasSlots"
      flat
      color="transparent"
      class="calendar text-left mx-n4 my-8"
    >
      <calendar-view
        :slots="controller.slots"
        :hive="hive"
        :selected="controller.selected"
        :loading="controller.loading"
        :cancelable="controller.cancelable"
        @select="setSelectedSlot"
        @refresh="getCalendarSlots"
        @cancel="cancelSelectedSlot"
      />
    </v-card>

    <v-card 
      outlined
      color="transparent"
      class="faq text-left mx-n4 my-8 mt-12"
    >
      <v-list 
        flat
        color="transparent"
        class="py-0"
      >
        <v-list-item
          class="faq-item py-2"
        >
          <v-list-item-icon class="mr-4">
            <v-icon color="primary">{{ icons.mdiInformationOutline }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title class="text-subtitle-1 font-weight-medium">
              Orientações
            </v-list-item-title>
            <v-list-item-subtitle class="item-subtitle mt-2">
              No dia da instalação abordaremos os assuntos abaixo:
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          v-for="(faq, key) in faq"
          :key="key"
          class="faq-item py-1 secondary--text"
          @click="toggleHelp(true, 'activation', faq.section, faq.id)"
        >
          <v-list-item-title v-text="faq.title" />
        </v-list-item>
      </v-list>
      <!-- <v-divider class="mt-0" />
      <v-card-actions>
        <v-spacer />
        <v-btn
          color="primary"
          text
          @click="toggleHelp(true, 'activation')"
        >
          Ver mais
        </v-btn>
      </v-card-actions> -->
    </v-card>

    <slot name="quick-access" />

    <v-btn 
      text
      small
      class="help-btn"
      color="grey"
      @click="toggleHelp(true, 'signup')"
    >
      <v-icon
        small
        class="mr-4"
      >
        {{ icons.mdiHelpCircleOutline }}
      </v-icon>
      Precisa de ajuda?
    </v-btn>
  </div>
</template>

<style>

  .step-content .item-title {
    margin: 6px 0;
  }

  .step-content .item-subtitle {
    line-height: 1.4;
    text-overflow: initial;
    white-space: initial;
  }

  .step-content .calendar {
    max-width: initial;
  }
  .step-content .faq {
    max-width: initial;
    border-color: var(--v-grey-darken3) !important;
  }

  .step-content .faq .faq-item:not(:last-child) {
    border-bottom: 1px solid var(--v-grey-darken3) !important;
  }

</style>

<script>

  // Icons
  import {
    mdiHelpCircleOutline,
    mdiInformationOutline,
    mdiClose,
    mdiCalendarAlert,
    mdiCheck
  } from '@mdi/js'

  // Utilities
  import services from '@/services.js'
  import { sync, get } from 'vuex-pathify'
  import _ from 'lodash';
  const moment = require('moment');


  export default {
    name: 'Step6',

    props: {
      step: {
        type: Object,
        default: () => {}
      },
      hive: {
        type: Object,
        default: () => null
      },
      appURL: {
        type: String,
        default: ''
      },
      refresh: {
        type: Boolean,
        default: false
      }
    },

    components: {
      CalendarView: () => import('@/components/CalendarView'),
    },

    data: () => ({
      api: process.env.VUE_APP_ROOT_API,
      icons: {
        mdiHelpCircleOutline,
        mdiInformationOutline,
        mdiClose,
        mdiCalendarAlert,
        mdiCheck
      },
      controller: {
        toggle: false,
        slots: {},
        selected: null,
        loading: false,
        cancelable: false,
      },
      faq: [
        {
          title: 'Modelo de Remuneração',
          section: 'goal',
          id: 9,
        },
        {
          title: 'Manual do Equipamento',
          section: 'manual',
          id: null,
        },
      ],
    }),

    computed: {
      user: sync('user'),
      status: sync('user/status'),
      toast: sync('app/toast'),
      help: sync('app/views@help'),
      help_sections: sync('help/sections'),

      stepName () {
        return this.$options.name.toLowerCase();
      },

      hasSlots () {
        return _.size(this.controller.slots)>0;
      }
    },

    watch: {
      refresh (b) {
        if (b) this.getCalendarSlots();
      }
    },

    methods: {
      ...services,
      
      openLocation (uri, target) {
        window.open(uri, target);
      },
      
      actionCalled () {
        if (this.completion==100) {
          this.toggleConfirm(true);
        }
      },


      setSelectedSlot (slot) {
        this.controller.loading = true;

        const cpf = this.rawCPF(this.user.cpf);
        const token = this.user.auth.token;
        // const key = this.getCalendarKey(this.user);
        const id = slot.id;

        this.$api.get('/selectslotcalendar/'+cpf+'/'+token+'/'+id+'/?format=json')
          .then(response => {
            console.log('setSelectedSlot => ',response);
            if(response.data.retorno==200){
              this.controller.selected = slot;

              this.setStepText();

              this.toggleToast(
                true,
                'Agendamento confirmado! 👍',
                5000,
                false,
              );

              this.trackEvent('signup/'+this.stepName, 'confirm installation ok');
            }else if(response.data.retorno==404){
              this.toggleToast(
                true,
                'O horário selecionado não está mais disponível.',
                5000,
                false,
              );
              this.trackEvent('signup/'+this.stepName, 'installation slot taken');
            }else{
              this.toggleToast(
                true,
                'Não foi possível salvar o horário selecionado. Tente novamente, por favor.',
                5000,
                false,
              );
              this.trackEvent('signup/'+this.stepName, 'confirm installation failed');
            }
          })
          .catch(error => {
            this.handleError(error, 'Não foi possível salvar o horário selecionado. Tente novamente, por favor.', true);
          })
          .finally(() => {
            this.getCalendarSlots();
            this.controller.loading = false;
          });
      },

      cancelSelectedSlot (slot) {
        this.controller.loading = true;

        const cpf = this.rawCPF(this.user.cpf);
        const token = this.user.auth.token;
        // const key = this.getCalendarKey(this.user);
        const id = slot.id;

        this.$api.get('/deleteslotcalendar/'+cpf+'/'+token+'/?slot_id='+id)
          .then(response => {
            console.log('cancelSelectedSlot => ',response);
            if(response.data.retorno==200){
              this.controller.selected = null;

              this.setStepText();

              this.toggleToast(
                true,
                'Horário desmarcado com sucesso. Não deixe de marcar outro horário 😉',
                5000,
                false,
              );

              this.trackEvent('signup/'+this.stepName, 'cancel installation ok');
            }else{
              this.toggleToast(
                true,
                'Não foi possível cancelar o horário. Tente novamente, por favor.',
                5000,
                false,
              );
              this.trackEvent('signup/'+this.stepName, 'cancel installation failed');
            }
          })
          .catch(error => {
            this.handleError(error, 'Não foi possível cancelar o horário. Tente novamente, por favor.', true);
          })
          .finally(() => {
            this.getCalendarSlots();
            this.controller.loading = false;
          });
      },

      getCalendarSlots () {
        this.controller.loading = true;

        const cpf = this.rawCPF(this.user.cpf);
        const token = this.user.auth.token;
        // const key = this.getCalendarKey(this.user);
        const type = 'INS';

        this.$api.get('/getslotscalendar/'+cpf+'/'+token+'/'+type)
          .then(response => {
            console.log('getCalendarSlots => ',response);
            if(response.data.retorno==200){
              let slots = _.groupBy(_.map(response.data.eventosLivres, e => {
                return {
                  id: e.id,
                  start: e.inicio,
                  end: e.fim,
                }
              }), s => moment(s.start).format('YYYY-MM-DD') );
              slots = _.mapValues(slots, s => {
                return _.keyBy(s, v => moment(v.start).hour() );
              })
              console.log('slots', slots);

              this.controller.slots = slots;
              this.controller.selected = !!response.data.eventoCpf ? {
                id: response.data.eventoCpf.id,
                start: response.data.eventoCpf.inicio,
                end: response.data.eventoCpf.fim,
              } : null;

              this.setStepText();

              this.trackEvent('signup/'+this.stepName, 'get installation slots ok');
            }else{
              this.toggleToast(
                true,
                'Não foi possível carregar os horários disponíveis.',
                5000,
                false,
              );
              this.trackEvent('signup/'+this.stepName, 'get installation slots failed');
            }
          })
          .catch(error => {
            this.handleError(error);
          })
          .finally(() => {
            this.controller.loading = false;
            if (this.refresh) this.$emit('onRefresh');
          });
      },

      setStepText () {
        if (!!this.controller.selected) {
          this.step.title = 'Instalação Agendada';
            this.step.subtitle = 'Sua instalação está confirmada para data e horário abaixo. Marque na sua agenda e fique atento ao horário!';
        }else{
          if (this.hasSlots) {
            this.step.title = 'Agende sua Instalação';
            this.step.subtitle = 'Chegou a hora! Confira os dias disponíveis no calendário abaixo e confirme seu horário.';
          }else{
            this.step.title = 'Estamos quase lá!';
            this.step.subtitle = 'Agora só falta liberar a data de instalação. Assim que for liberada, você será notificado.';
          }
        }
      }

    },

    created () {
    },

    mounted () {
      this.getCalendarSlots();
      this.setStepText();
    },

    directives: {
    },

  }
</script>
